import React, { useEffect } from 'react'
import styled from 'styled-components';
import firebase from '../../firebase';

const BetaScreen = styled.div`
  color:white;
  padding:20px;
`

const Beta = () => {

  useEffect(() => {

    const { analytics, logEvent } = firebase.init();
    logEvent(analytics, "page_view", { page_name: "default" });

  });
  return (
    <BetaScreen>Beta</BetaScreen>
  )
}

export default Beta;