import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

// demo code:
// https://github.com/firebase/codelab-friendlychat-web

const init = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyDd4TS3LtG8zLhYYDfGq6N0fgOnAIZmUR0",
    authDomain: "mdslides-0.firebaseapp.com",
    databaseURL: "https://mdslides-0-default-rtdb.firebaseio.com",
    projectId: "mdslides-0",
    storageBucket: "mdslides-0.appspot.com",
    messagingSenderId: "407696533975",
    appId: "1:407696533975:web:1544620edd13f3d6d6c392",
    measurementId: "G-CMFL9X5X20"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  const analytics = getAnalytics(app);

  const functions = getFunctions(app, "us-central1");
  const helloWorld = httpsCallable(functions, 'helloWorld');
  helloWorld()
  .then((result) => {
    // Read result of the Cloud Function.
    const data: any = result.data;
    const sanitizedMessage = data.text;
    console.log(sanitizedMessage)
  });

  return { 
    app,
    analytics,
    logEvent
  };
};


export default { init }