import { DefaultTheme } from "styled-components";

export interface MdSlidesTheme extends DefaultTheme {
  
  accentColor: string;

  button: {
    default: {
      backgroundColor: string;
      backgroundColorHover: string;
    }
    primary?: {
      backgroundColor: string;
      backgroundColorHover: string;
    }
  }

  toolbar: {
    accentColor: string;
    backgroundColor: string;
  }
}

export const theme: MdSlidesTheme = {
  
  accentColor: "#2f68ed",

  toolbar: {
    accentColor: "#2f68ed",
    backgroundColor: "#222"
  },

  button: {
    default: {
      backgroundColor: "#333",
      backgroundColorHover: "#444",
    },
    primary: {
      backgroundColor: "#2f68ed",
      backgroundColorHover: "#467af2",
    }
  }
}