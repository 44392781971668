import React from 'react';

interface FontsContextType {
  fonts: string[];
  setFonts: React.Dispatch<React.SetStateAction<string[]>>;
}

const FontsContext = React.createContext<FontsContextType>(undefined as any);

function FontsProvider(props) {
  const [fonts, setFonts] = React.useState<string[]>([])

  const value: FontsContextType = React.useMemo(() => {
    return {
      fonts,
      setFonts,
    }
  }, [fonts]);
  return <FontsContext.Provider value={value} {...props} />
}

const makeFontHeader = (font: string) => {
  const link = document.createElement('link');
  link.setAttribute("crossorigin", "anonymous");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("href", `https://fonts.googleapis.com/css2?family=${encodeURIComponent(font)}&amp;display=swap`);
  return link;
}

function useFonts() {
  const context = React.useContext(FontsContext)
  if (!context) {
    throw new Error('useFonts must be used within a FontsProvider')
  }
  const { fonts, setFonts } = context
  const addFont = React.useCallback((font) => {
    //console.log('addFont', font);
    // Add to HTML

    const link = makeFontHeader(font);
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);

    setFonts(fonts => {
      return [...fonts, font]
    });
  }, [setFonts]);

  const removeFont = React.useCallback((font) => {
    // Remove from HTML
    setFonts(fonts => {
      return [...fonts.filter(f => f === font)]
    });
  }, [setFonts]);

  const loadFontHeaders = async (fontList: string[]) => {
    return fontList.map(font => makeFontHeader(font).outerHTML).join("");
  }

  const loadFonts = async (fontList: string[]) => {
    console.log("handleEditorChange", fontList);
    const validationErrors: string[] = [];

    const $invalidFonts = await invalidFonts(fontList);
    $invalidFonts.forEach(async font => {
      validationErrors.push(`Font ${font} is not available`);
    })

    if ($invalidFonts.length === 0) {
      // add fonts 
      fontList.forEach(font => {
        if (!fonts.includes(font)) {
          addFont(font);
        }
      });
    }

    return validationErrors;
  }

  return {
    loadFonts,
    loadFontHeaders,
    loadedFonts: fonts,
    removeFont,
    addFont,
  }
}

// temp, load from Google cache on firebase
const $availableFonts = [
  'Roboto',
  'Fira Code',
  'Lato',
  'Open Sans',
  'Koulen',
  'Poppins',
  'Montserrat',
  'Source Sans Pro',
  'Raleway',
  'Inter',
];

const availableFonts = () => {
  return new Promise<string[]>((resolve, reject) => {
    // TODO: Call to Firebase for font list
    resolve($availableFonts);
  });
}

const invalidFonts = async (fonts: string[]) => {
  const avfonts = await availableFonts();
  return fonts.filter(font => !avfonts.includes(font));
}

export { FontsProvider, useFonts, invalidFonts, availableFonts };
