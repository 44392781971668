import React from 'react'
import { ThemeProvider } from 'styled-components';
import { theme } from './designsystem/theme';
import { FontsProvider } from './features/fonts/FontContext';

const AppProviders = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <FontsProvider>
        {children}
      </FontsProvider>
    </ThemeProvider>
  )
}

export default AppProviders