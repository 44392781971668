import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
const App = React.lazy(() => import('./App'));
import reportWebVitals from './reportWebVitals';
import AppProviders from './AppProviders';
import Beta from './features/betaux/Beta';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const defaultFeature = process.env.REACT_APP_FEATURE;

// Removing strict mode to prevent useEffect runnign twice in development
// <React.StrictMode>
// </React.StrictMode>
root.render(
  <AppProviders>
    {defaultFeature === "App" ? <App /> : <Beta />}
  </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
